import React, { useEffect } from 'react'
import './privacidade.css'
import { Link } from 'react-router-dom'

function Policy() {
    useEffect(()=>{
  window.scrollTo(0,0);
},[])
  return (
    <>
      <div className="back">
        <Link to={'/'}>
          <svg width="100%" height="auto" viewBox="0 0 74 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M73 61.7058C64.192 50.9889 56.3704 44.908 49.5352 43.4632C42.7 42.0183 36.1924 41.8 30.0124 42.8083V62L1 30.6837L30.0124 1V19.2408C41.44 19.3305 51.1552 23.4169 59.158 31.5C67.1596 39.5831 71.7736 49.6517 73 61.7058Z" stroke-linejoin="round"/>
            </svg>
        </Link>
    </div>
    <div className="PP">
        <div className="PPtitulo">Under Construction</div>
    </div>
    </>
  )
}

export default Policy