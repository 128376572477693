import React from "react";
import {Routes, Route} from 'react-router-dom';
import { AuthContextProvider } from "../services/AuthContext";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Home from "../pages/Home";
import Login from "../pages system/Login";
import DataUpdate from "../pages system/DataUpdate";
import ImageList from '../pages system/ImageList';
import ImageUpload from '../pages system/ImageUpload';
import NewsList from '../pages system/NewsList';
import NewsUpload from"../pages system/NewsUpload";
import NewsUpdate from "../pages system/NewsUpdate";
import Unfound from "../pages/Unfound";
import PoliticaPrivacidade from "../pages/PoliticaPrivacidade";
import Policy from "../pages/Policy";



export const RouteList =()=>{

    return(
        <AuthContextProvider>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path= '/*'  element={<Unfound />} />
                <Route path= '/privacidade'  element={<PoliticaPrivacidade />} />
                 <Route path= '/privacidade-eng'  element={<Policy />} />
                <Route path="/dataupdate" element={
                    <ProtectedRoutes>
                        <DataUpdate />
                   </ProtectedRoutes>
                } />
                <Route path="/imagelist" element={
                    <ProtectedRoutes>
                        <ImageList />
                   </ProtectedRoutes>
                } />
                <Route path="/imageupload" element={
                    <ProtectedRoutes>
                        <ImageUpload />
                   </ProtectedRoutes>
                } />
                <Route path="/newslist" element={
                    <ProtectedRoutes>
                        <NewsList />
                   </ProtectedRoutes>
                } />
                <Route path="/newsupload" element={
                    <ProtectedRoutes>
                        <NewsUpload/>
                   </ProtectedRoutes>
                } />
                 <Route path="/newsupdate/:id" element={
                    <ProtectedRoutes>
                        <NewsUpdate />
                   </ProtectedRoutes>
                } />
                

           
            </Routes>
        </AuthContextProvider>
    );

}