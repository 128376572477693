import React, { useEffect } from 'react'

import './privacidade.css'
import { Link } from 'react-router-dom'

function PoliticaPrivacidade() {
  useEffect(()=>{
  window.scrollTo(0,0);
},[])
  return (
    <>
     <div className="back">
        <Link to={'/'}>
          <svg width="100%" height="auto" viewBox="0 0 74 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M73 61.7058C64.192 50.9889 56.3704 44.908 49.5352 43.4632C42.7 42.0183 36.1924 41.8 30.0124 42.8083V62L1 30.6837L30.0124 1V19.2408C41.44 19.3305 51.1552 23.4169 59.158 31.5C67.1596 39.5831 71.7736 49.6517 73 61.7058Z" stroke-linejoin="round"/>
            </svg>
        </Link>
    </div>
    <div className="PP">
      <div className="PPtitulo">POLÍTICA DE PRIVACIDADE</div>
      <div className="PPtexto">
        Nós, da NEXO COM ASSESSORIA EM COMUNICAÇÃO LTDA (“NEXOINTELL”), pessoa jurídica de direito privado, inscrita no CNPJ sob o n. 03.384.921/0001-93 e com sede na Cidade de São Paulo, Estado de São Paulo, na Av. Paulista, nº 1.471, CJ. 511, Bela Vista, CEP 01311-200, temos o compromisso em garantir a transparência nas informações (“Dados Pessoais”) que tratamos e, por esse motivo, disponibilizamos ao usuário (“Você”) a presente Política de Privacidade.
      </div>

      <div className="PPtitulo1">1. NOSSO COMPROMISSO COM A SUA PRIVACIDADE</div>
       <div className="PPtexto">
        Somos uma Empresa de inteligência corporativa com mais de 4.300 investigações em 22 anos de atuação na América Latina e estamos realmente empenhados em proteger a sua privacidade, garantindo que seus Dados Pessoais sejam tratados de acordo com a Lei Geral de Proteção de Dados, Lei nº 13.709/2019 (“LGPD”) e com as melhores boas práticas de mercado. Por isso, somos transparentes em relação às nossas práticas e estamos à disposição para conversar sempre que você quiser.
        Por isso, veja abaixo alguns dos princípios que orientam a forma pela qual tratamos seus Dados Pessoais:<br/><br/>
        <ol type='i'>
          <li>  Todo tratamento dos seus Dados Pessoais será realizado de acordo com a “Política de Privacidade” e somente para cumprir as finalidades aqui especificadas.</li><br/>
          <li>  Quando algum dos seus Dados Pessoais deixar de ser necessário e não tivermos a obrigação legal de armazená-lo, nossa intenção é excluí-lo, destruí-lo ou anonimizá-lo.</li><br/>
          <li>  Nossa intenção é coletar a menor quantidade possível dos seus Dados Pessoais necessários para atender às finalidades aqui especificadas.</li>
        </ol>

       </div>
       <div className="PPtitulo1">2. DEFINIÇÕES IMPORTANTES</div>
       <div className="PPtexto">
          Veja abaixo algumas definições importantes para entender melhor a proteção que aplicamos aos seus Dados Pessoais, os limites do nosso uso e seus direitos, de acordo com a Lei Geral de Proteção de Dados (“LGPD”):<br/><br/>
          <ol type='i'>
            <li>  “Dado Pessoal” são todas as suas informações coletadas ou de qualquer outra forma tratada pela NEXOINTELL que possa identificá-lo como pessoa.</li><br/>
            <li>  “Dado Pessoal Sensível” significa dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural, nos termos da LGPD; originados ou coletados pelo Controlador; ou aqueles coletados, gerados, ou de qualquer maneira tratados pelo Controlador.</li><br/>
            <li>  “Tratamento” é toda operação realizada com seus dados pessoais pela NEXOINTELL nos termos previstos nesta Política de Privacidade, tais como, exemplificativamente, a coleta, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação etc.</li><br/>
            <li>  “Transferência Internacional” significa transferência de dados pessoais do Brasil para país estrangeiro ou organismo internacional do qual o país seja membro.</li><br/>
            <li>  “Anonimização de Dados” é o processo pelo qual um dado pessoal deixa de ser identificado a você.</li><br/>
            <li>  “Titular” é você.</li><br/>
            <li>  “Controlador” é a pessoa a quem compete a tomada de decisões referentes ao tratamento dos seus dados pessoais, neste caso a NEXOINTELL.</li><br/>
            <li>  “Operador” é a pessoa que irá realizar o tratamento dos seus dados pessoais em nome da NEXOINTELL.</li><br/>
            <li>  “Encarregado” (“Data Protection Officer” ou “DPO”) é a pessoa designada para atuar como canal de comunicação entre você e a NEXOINTELL no que diz respeito à aplicação da LGPD no momento do tratamento dos seus dados pessoais no site, cabendo a ele, entre outras atividades, aceitar as suas reclamações e comunicações, prestando todos os esclarecimentos e adotando as providências devidas.</li><br/>
            <li> “Autoridade Nacional de Proteção de Dados” (“ANPD”) é o órgão da Administração Pública responsável por zelar, implementar e fiscalizar o cumprimento da LGPD em todo o território nacional.</li><br/>
            <li>  “Lei Geral de Proteção de Dados” (“LGPD”) é a Lei nº 13.709/2018 que dispõe sobre o tratamento dos seus dados pessoais com o objetivo de proteger seus direitos fundamentais de liberdade e de privacidade.</li><br/>
            <li>  “Melhores Práticas do Mercado” significam normas, práticas, métodos e procedimentos conformes com as Normas Aplicáveis e o grau de capacidade e zelo, diligência, prudência e precaução que seria razoável e comumente esperado de operador qualificado e experiente participante de atividade semelhante em circunstâncias semelhantes.</li><br/>
          </ol>
       </div>
       <div className="PPtitulo1">3. QUE TIPO DE DADOS PESSOAIS A NEXOINTELL TRATA</div>
       <div className="PPtexto">
          Os tipos de Dados Pessoais e a forma como a NEXOINTELL os coleta dependem de como você se relaciona conosco e por quê. Por exemplo, os Dados Pessoais coletados serão diferentes caso você faça parte do nosso time de Colaboradores e Analistas, ou utilize os serviços que prestamos, ou ainda seja apenas um usuário do nosso site.<br/>
          Todos os dados coletados para cada uma das finalidades especificas respeita o princípio da minimização, ou seja, são coletados o mínimo de dados possíveis que a finalidade do tratamento seja alcançada, preservando, assim, a sua privacidade.
       </div>
       <div className="pplinha">
         <div className="cxEsquerda">
          Tipos de Dados Pessoais utilizados:
         </div>
         <div className="cxDireita">
          Dados de Identificação (ex. nome, rg, cpf etc.); Dados de Contato (ex. tel., e-mail, etc.); Dados de Localização (ex. endereço); Dados Acadêmicos; Dados Financeiros e Fiscais.
         </div>
       </div>
       <div className="PPtexto">
          Podemos ainda tratar os seus dados pessoais a pedido de nosso Clientes, para em seu nome e sob sua determinação e orientação, realizar o tratamento de seus dados. Neste cenário, os nossos Clientes são os Controladores dos seus dados e nós apenas atuamos nessas situações como Operadora dos seus dados, segundo as definições contidas na LGPD.<br/>
          Nessa hipótese, além do tratamento dos dados informados no quadro acima, também podemos vir a tratar os seguintes dados:
       </div>
       <div className="pplinha">
         <div className="cxEsquerda">
          Tipos de Dados Pessoais utilizados:
         </div>
         <div className="cxDireita">
          Dados Sensíveis: (dados referentes à saúde, opiniões políticas, orientação sexual).
         </div>
       </div>
       <div className="PPtexto">
          Reforçamos para que os dados de caráter sensível, os coletamos para finalidade especificas e restringimos o acesso somente aos interessados. Informamos ainda que cabe ao nosso Cliente, enquanto Controlador dos seus dados, definir a base legal que fundamentará o tratamento dos seus dados pessoais.
       </div>
        <div className="PPtitulo1">4. COM QUEM A NEXOINTELL COMPARTILHA OS SEUS DADOS PESSOAIS?</div>
        <div className="PPtexto">
          Nós operamos em parceria com uma série de outras organizações para viabilizar as nossas atividades, como por exemplo, soluções em nuvem para armazenamento dos dados, serviço contábil/fiscal e suporte de infraestrutura de tecnologia. Mas fique tranquilo, pois seus dados serão compartilhados somente em situações essenciais específicas e necessárias, tais como as mencionadas acima. Não comercializamos seus dados para terceiros.
        </div>
        <div className="PPtitulo1">5. QUAIS SÃO OS SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?</div>
        <div className="PPtexto">
          Os Dados Pessoais são seus e a lei brasileira lhe garante uma série de direitos relacionados a eles. Nós estamos comprometidos com o cumprimento desses direitos e, nessa seção, vamos explicar quais são esses direitos e como você pode exercê-los. Veja a seguir:
        </div>
        <div className="pplinha1" style={{marginTop:'20px'}}>
         <div className="cxEsquerda1" style={{textAlign:'center', fontWeight:'700'}}>
          Seus Direitos
         </div>
         <div className="cxDireita1" style={{textAlign:'center', fontWeight:'700'}}>
          Explicação
         </div>
       </div>
       <div className="pplinha1">
         <div className="cxEsquerda1">
          Confirmação e Acesso
         </div>
         <div className="cxDireita1">
          Você pode nos solicitar a confirmação sobre a existência de tratamento dos seus Dados Pessoais para que, em caso positivo, você possa acessá-los, inclusive por meio de solicitação de cópias dos registros que temos sobre você.
         </div>
       </div>
        <div className="pplinha1">
         <div className="cxEsquerda1">
          Correção
         </div>
         <div className="cxDireita1">
          Você pode solicitar a correção dos seus Dados Pessoais caso estes estejam incompletos, inexatos ou desatualizados.
         </div>
       </div>
       <div className="pplinha1">
         <div className="cxEsquerda1">
          Anonimização, bloqueio ou eliminação
         </div>
         <div className="cxDireita1">
          Você pode solicitar (a) a anonimização dos seus Dados Pessoais, de forma que eles não possam mais ser relacionados a você e, portanto, deixem de ser Dados Pessoais; (b) o bloqueio dos seus Dados Pessoais, suspendendo temporariamente a sua possibilidade de os tratarmos para certas finalidades; e (c) a eliminação dos seus Dados Pessoais, caso em que deveremos apagar todos os seus Dados Pessoais sem possibilidade de reversão.
         </div>
       </div>
       <div className="pplinha1">
         <div className="cxEsquerda1">
          Portabilidade
         </div>
         <div className="cxDireita1">
          Você pode nos solicitar que forneçamos os seus Dados Pessoais em formato estruturado e interoperável visando à sua transferência para um terceiro, desde que essa transferência não viole a propriedade intelectual ou segredo de negócios da Empresa.
         </div>
       </div>
        <div className="pplinha1">
         <div className="cxEsquerda1">
          Informação sobre o compartilhamento
         </div>
         <div className="cxDireita1">
          Você tem o direito de saber quais são as entidades públicas e privadas com as quais a NEXOINTELL realiza uso compartilhado dos seus Dados Pessoais. Manteremos, no item 3 dessa Política, uma indicação das nossas relações com terceiros que podem envolver o compartilhamento de Dados Pessoais. Em todo caso, se você tiver dúvidas ou quiser mais detalhes, você tem o direito de nos solicitar essas informações. A depender do caso, podemos limitar as informações fornecidas a você caso a sua divulgação possa violar a propriedade intelectual ou segredo de negócios da Empresa.
         </div>
       </div>
       <div className="pplinha1">
         <div className="cxEsquerda1">
          Informação sobre a possibilidade de não consentir
         </div>
         <div className="cxDireita1">
            Você tem o direito de receber informações claras e completas sobre a possibilidade e as consequências de não fornecer consentimento, quando ele for solicitado pela NEXOINTELL. O seu consentimento, quando necessário, deve ser livre e informado. Portanto, sempre que pedirmos seu consentimento, você será livre para negá-lo – nesses casos, é possível que alguns serviços não possam ser prestados.
         </div>
       </div>
        <div className="pplinha1">
         <div className="cxEsquerda1">
          Revogação do consentimento
         </div>
         <div className="cxDireita1">
            Caso você tenha consentido com alguma finalidade de tratamento dos seus Dados Pessoais, você pode sempre optar por retirar o seu consentimento. No entanto, isso não afetará a legalidade de qualquer Tratamento realizado anteriormente à revogação. Se você retirar o seu consentimento, é possível que fiquemos impossibilitados de lhe prestar certos serviços, mas iremos avisá-lo quando isso ocorrer.
         </div>
       </div>
         <div className="pplinha1" style={{marginBottom:'25px'}}>
         <div className="cxEsquerda1">
          Oposição
         </div>
         <div className="cxDireita1">
            A lei autoriza o tratamento de Dados Pessoais mesmo sem o seu consentimento ou um contrato conosco. Nessas situações, somente trataremos seus Dados Pessoais se tivermos motivos legítimos para tanto, como, por exemplo, quando for necessário para garantir a segurança de nossas rodovias. Caso você não concorde com alguma finalidade de tratamento dos seus Dados Pessoais, você poderá apresentar oposição, solicitando a sua interrupção.
         </div>
       </div>
       <div className="PPtitulo3">AVISOS IMPORTANTES</div>
       <div className="PPtexto" style={{border:'1px solid #3b5272', padding:'10px'}}>
          Para sua segurança, sempre que você apresentar uma requisição para exercer seus direitos, nós poderemos solicitar algumas informações e/ou documentos complementares para que possamos comprovar a sua identidade, buscando impedir fraudes. Fazemos isso para garantir a segurança e a privacidade de todos.<br/><br/>
          Em alguns casos, nós podemos ter motivos legítimos para deixar de atender a uma solicitação de exercício de direitos. Essas situações incluem, por exemplo, casos em que uma revelação de informações específicas poderia violar direitos de propriedade intelectual ou segredos de negócio da NEXOINTELL ou de terceiros, bem como casos em que pedidos de exclusão de dados não possam ser atendidos em razão da existência de obrigação da NEXOINTELL de reter dados, seja para cumprir obrigações legais, regulatórias ou para possibilitar a defesa da NEXOINTELL ou de terceiros em disputas de qualquer natureza.<br/><br/>
          Ainda, algumas solicitações podem não ser respondidas de forma imediata, mas nós nos comprometemos a responder todas as requisições em um prazo razoável e sempre em conformidade com a legislação aplicável.<br/>

       </div>
       <div className="PPtexto" style={{marginTop:'20px'}}>
        Caso você tenha alguma dúvida sobre essas questões ou sobre como você pode exercer seus direitos, fique à vontade para entrar em contato conosco por meio dos canais informados ao final desta Política
       </div>
        <div className="PPtitulo1">6. POR QUANTO TEMPO OS DADOS PESSOAIS SERÃO ARMAZENADOS?</div>
         <div className="PPtexto">
          Os Dados Pessoais são armazenados somente pelo tempo que forem necessários para cumprir com as finalidades para as quais foram coletados, salvo se houver qualquer outra razão para sua manutenção como, por exemplo, cumprimento de quaisquer obrigações legais, regulatórias, contratuais, de auditorias, entre outras permitidas sob a lei.<br/>
          Sempre fazemos uma análise técnica para determinar o período de retenção adequado para cada tipo de Dado Pessoal coletado, considerando a sua natureza, necessidade de coleta e finalidade para a qual ele será tratado, bem como eventuais necessidades de retenção para o cumprimento de obrigações ou o resguardo de direitos.
         </div>
          <div className="PPtitulo1">7. TRANSFERÊNCIA INTERNACIONAL DE DADOS</div>
           <div className="PPtexto">
            Os seus Dados Pessoais, via de regra, não são transferidos para empresas que estejam situadas fora território nacional, contudo se porventura vierem a ser transferidos para fora do Brasil, o que de acordo com a legislação nacional constitui “Transferência Internacional de Dados”, você será previamente informado a este respeito.<br/>
            Nesta hipótese, asseguramos que seus Dados Pessoais também serão protegidos no exterior. Nós só transferiremos seus Dados Pessoais para países ou organismos internacionais que proporcionem grau de proteção semelhante ao previsto na legislação brasileira e tomaremos as medidas adequadas para garantir que eles estarão em segurança.
           </div>
          <div className="PPtitulo1">8. COLETA DE <span style={{fontStyle:'italic'}}>COOKIES</span></div>
          <div className="PPtexto">
            O que são cookies: Cookies são arquivos instalados no dispositivo de um usuário que permitem a coleta de determinadas informações, inclusive dados pessoais em algumas situações, visando ao atendimento de finalidades diversas.<br/><br/>
            Categorias de cookies:<br/><br/>
            <ol type='i'>
              <li>  Cookies próprios ou primários: são os cookies definidos diretamente pelo site ou aplicação que Você, Titular, está visitando. Estes cookies não possuem a finalidade de rastrear suas atividades em outro site que não seja o nosso site <Link to={'/'}>https://www.nexointell.com/</Link>. Estes tipos de cookies podem incluir informações como credenciais de acesso à nossa área restrita. </li>
              <li>  Cookies de Terceiros: são cookies criados por um domínio diferente daquele que Você, Titular, está visitando. Decorrem de funcionalidades de outros domínios que são incorporados a uma página eletrônica, a exemplo de exibição de anúncios. Mas fique tranquilo, nosso site não utiliza de cookies de terceiros.</li>
              <li>  Cookies de funcionalidade: Nos permitir relembrar as preferências do usuário. Por exemplo, os cookies evitam digitar o nome do utilizador cada vez que este acede ao site. Também usamos cookies de funcionalidade para fornecer serviços avançados ao usuário, como por exemplo efetuar comentários a um artigo. Em resumo, os cookies de funcionalidade guardam as preferências do usuário relativamente à utilização do site, de forma que não seja necessário voltar a configurar o site cada vez que o visita, bem como assegura a disponibilização de funcionalidades adicionais do site.</li>
              <li>  Cookies analíticos: São cookies próprios para analisar a forma como o usuário usa o site e monitorar a performance deste. Por exemplo, cookies de desempenho para saber quais as páginas mais populares, qual o método de ligação entre páginas que é mais eficaz, ou para determinar a razão de algumas páginas estarem a receber mensagens de erro. Baseado na utilização do site, é possível também utilizar estes cookies para destacar artigos ou serviços do site que possa ser do interesse dos usuários. Estes cookies são utilizados apenas para efeitos de criação e análise estatística, sem nunca recolher informação de carácter pessoal. Contudo, em nosso site não há a coleta deste tipo de cookies.</li>
            </ol>
          </div>
           <div className="PPtitulo1">9. COMO NÓS PROTEGEMOS SEUS DADOS PESSOAIS?</div>
            <div className="PPtexto">
              Nossa responsabilidade é cuidar dos seus Dados Pessoais e utilizá-los somente para as finalidades descritas nessa Política. Para garantir a sua privacidade e a proteção dos seus Dados Pessoais, adotamos recursos tecnológicos avançados para garantir a segurança de todos os dados tratados por nós. Entre as medidas de segurança implementadas estão a implementação de controles de acesso a sistemas e ambientes de tratamento de dados, técnicas de criptografia e a instalação de barreiras contra o acesso indevido às bases de dados (incluindo firewalls), entre outros controles de segurança da informação.<br/>
              Nós nos esforçamos para proteger a privacidade de seus Dados Pessoais, mas infelizmente não podemos garantir total segurança. Entradas e usos não autorizados de terceiros com informações suas, falhas de hardware ou software que não estejam sob o nosso controle e outros fatores externos podem comprometer a segurança dos seus Dados Pessoais. Por isso, sua atuação é fundamental para a manutenção de um ambiente seguro para todos. Caso você identifique ou tome conhecimento de qualquer fator que comprometa a segurança dos seus dados na sua relação com a NEXOINTELL, por favor entre em contato conosco por meio das informações de contato indicadas abaixo.

            </div>
          <div className="PPtitulo1">10. COMO FALAR COM A EMPRESA?</div>
          <div className="PPtexto">
            Se você acredita que seus Dados Pessoais foram tratados de maneira incompatível com esta Política ou com as suas escolhas enquanto Titular dos seus Dados Pessoais, ou, ainda, se você tiver dúvidas, comentários ou sugestões relacionadas a esta Política e à forma como tratamos seus Dados Pessoais, entre em contato conosco nos seguintes endereços de contato:<br/><br/>
            Responsável: Antonio Elias<br/><br/>
            E-mail: <a href="mailto:antonioelias@nexointell.com">antonioelias@nexointell.com</a>

          </div>
          <div className="PPtitulo1">11. MUDANÇAS NA POLÍTICA</div>
          <div className="PPtexto">
            Como nós sempre estamos buscando melhorar seus serviços, essa Política pode passar por atualizações. Desta forma, recomendamos que Você visite periodicamente esta página para ter conhecimento sobre as modificações. Caso sejam feitas alterações relevantes que necessitem de um novo consentimento, iremos publicar essa atualização e solicitar a Você um novo consentimento.
          </div>
    </div>
    
    </>
  )
}

export default PoliticaPrivacidade