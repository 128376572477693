import React, {useState} from 'react'
import './presence.css';

import Spa from '../../image/esp.webp';


function Presence() {
  //ativando botoes
  


  return (

    <section className="presence" id='presence'>
      <div className="preContainer">
        <div className="preTitle">
        Presence
        </div>
       
        <div className="preItemArea">

            <div className={`preItem preItemActive`}>
                <div className="preItemMap">
                      <img src={Spa} alt="Spain" width={'100%'} height={"auto"} />
                </div>
                <div className="preItemInfo">

                  
                  <div className="preItemText">
                  Nexointell is present in 22 jurisdictions in the Americas and the Iberian Peninsula.<br/>
                  We are incorporated in Brazil and the United States, and have offices in Lisbon (Portugal) and São Paulo (Brazil).<br/>
                  Nexointell has further presence and is able to conduct corporate intelligence work in the following jurisdictions: Brazil, Argentina, Chile, Uruguay, Paraguay, Bolivia, Peru, Colombia, Ecuador, Venezuela, Suriname, Mexico, Costa Rica, Panama, El Salvador, Nicaragua, Guatemala, Honduras, United States, Canada, Portugal and Spain.
                  </div>
                </div>
            </div>

        </div>


        
      </div>
    </section>
  )
}

export default Presence