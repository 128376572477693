import React from 'react'
import './footer.css'

function Footer() {
  return (
    <footer className="footer" id='contact'>
      <div className="footerContainer">
        <div className="footer1">
          <div className="footerItem">
              <div className="footerTitle">CEO / Founding Partner:</div>
              <div className="footerAdress">
              <span>Luis Esnal</span><br/>
              luisesnal@nexointell.com
              
              </div>
            </div>
            <div className="footerItem">
              <div className="footerTitle">Executive Director / Partner:</div>
              <div className="footerAdress">
              <span>Antonio Elias</span><br/>
              antonioelias@nexointell.com
              
              </div>
          </div>
        </div>
        <div className="footer2">
          <div className="footerItem">
              <div className="footerTitle">São Paulo, Brazil:</div>
              <div className="footerAdress">
              <span>Corporate Headquarters</span><br/>
              +55 11 2626 8405<br/>
              nexointell@nexointell.com<br/>
              Av. Paulista, 1471 - Bela Vista<br/>
              São Paulo - SP<br/>
              01311-200, Brasil.
              </div>
          </div>
        </div>
        <div className="footer3">
          <div className="footerItem">
              <div className="footerTitle">Lisbon, Portugal:</div>
              <div className="footerAdress">
              <span>Nexointell Corporate Intelligence</span><br/>
              Rua João de Oliveira Miguens 9 - Alcântara, Lisboa.<br/>
              +351 962 937 820
              </div>
          </div>
          <div className="footerItem">
              <div className="footerTitle">United States:</div>
              <div className="footerAdress">
              <span>Nexointell Corporate Intelligence, LLC</span><br/>
              7345 W Sand Lake Rd Ste 201 Office 8384 Orlando, FL 32819<br/>
              +1 (646) 948 1510
              </div>
          </div>
        </div>
      </div>
    </footer>
  )
}


export default Footer