import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './lgpd.css'

function Lgpd() {
    const [mostra, setMostra]=useState(false)
   
    useEffect(()=>{
      setTimeout(
        setMostra(true), 10000
        )
        },[])
  return (
    <div className={mostra? 'lgpdWarper active' : 'lgpdWarper'}>
        <div className="lgpdEsquerda">
            <div className="texto">
                Visit our <Link to={'/privacidade-eng/'}>Privacy Policy</Link> to find out more about how we treat the data collected in our projects.<br/><br/>
                Acesse nossa <Link to={'/privacidade/'}>Política de Privacidade</Link> para saber mais sobre como tratamos os dados coletados em nossos projeto.
            </div>
        </div>
        <div className="lgpdDireita">
            <button onClick={()=>{setMostra(false)}}>
                <svg width="20" height="20" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M53.3333 0H6.66667C4.89856 0 3.20286 0.702379 1.95262 1.95262C0.702379 3.20286 0 4.89856 0 6.66667V53.3333C0 55.1014 0.702379 56.7971 1.95262 58.0474C3.20286 59.2976 4.89856 60 6.66667 60H53.3333C55.1014 60 56.7971 59.2976 58.0474 58.0474C59.2976 56.7971 60 55.1014 60 53.3333V6.66667C60 4.89856 59.2976 3.20286 58.0474 1.95262C56.7971 0.702379 55.1014 0 53.3333 0ZM42 46.6667L30 34.6667L18 46.6667L13.3333 42L25.3333 30L13.3333 18L18 13.3333L30 25.3333L42 13.3333L46.6667 18L34.6667 30L46.6667 42L42 46.6667Z" />
                </svg>
            </button>
        </div>
    </div>
  )
}

export default Lgpd