import React, { useEffect } from 'react'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Services from '../components/Services'
import Industries from '../components/Industries'
import Values from '../components/Values'
import Presence from '../components/Presence'
import Team from '../components/Team'
import Footer from '../components/Footer'
import News from '../components/News'
import FloatTop from '../components/FloatTop'
import Lgpd from '../components/Lgpd'

function Home() {
  useEffect(()=>{
  window.scrollTo(0,0);
},[])
  return (
    <>
        <Header />
        <Hero />
        <Services />
        <Industries />
        <Values />
        <Presence />
        <Team />
        <News />
        <Footer />
        <FloatTop />
        <Lgpd />
    </>
  )
}

export default Home